import React from 'react'

export default function SvgIcon ({
  src = '',
  color = '',
  className = '',
  alt = '',
}) {
  const style = {
    minWidth: '20px',
    minHeight: '20px',
    backgroundImage: `url(${src}) no-repeat center / contain`,
    mask: `url(${src}) no-repeat center / contain`,
    WebkitMask: `url(${src}) no-repeat center / contain`,
  }

  if (color) {
    style.backgroundColor = `${color}`
  }

  return (
    <div
      style={style}
      {...{
        className: `svg-icon ${className}`,
        alt: alt || className,
      }}
    />
  )
}
