import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import BookingSuccess from "./BookingSuccess";
import BookingForm from "./BookingForm";
import BookingExit from "./BookingExit";

import './style/index.scss';

export default function App () {
  useEffect(() => {
    document.title = '80PROOF Table Booking'
  }, [])

  const router = createBrowserRouter([
    {
      path: "/",
      element: <BookingForm />,
    },
    {
      path: "/success",
      element: <BookingSuccess />,
    },
    {
      path: "/exit",
      element: <BookingExit />,
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
};
