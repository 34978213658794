import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import VConsole from 'vconsole'

// Common Component
import SvgIcon from "./components/SvgIcon"

// Asset
import logo from './assets/images/logo-80proof.svg'
import FormItem from "./components/FormItem"
import { arrivalTimeConfig } from './assets/variable/bookingform'

// Form
import Input from 'rc-input'
import Select, { Option } from 'rc-select';
import { DatePickerInput } from 'rc-datepicker';
import TimePicker from 'rc-time-picker';
import 'rc-datepicker/lib/style.css';
import 'rc-time-picker/assets/index.css';
import Modal from './components/Modal'
import Spinner from './components/Spinner'

export default function BookingForm() {
  // query params 
  const navigate = useNavigate()
  const [token, setToken] = useState('')
  const [tableName, setTableName] = useState(null)
  const [tableId, setTableId] = useState(null) // for api as request id
  const [tableStatus, setTableStatus] = useState('')
  const [enumMaxPerson, setEnumMaxPerson] = useState([])
  const [minCharge, setMinCharge] = useState(0)
  const [totalMinCharge, setTotalMinCharge] = useState(0)
  const [userTier, setUserTier] = useState('')
  const [userTierDisc, setUserTierDisc] = useState('')
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalText, setModalText] = useState('')
  const [eventList, setEventList] = useState([])
  const [errorText, setErrorText] = useState('')

  
  // form state
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formBookingName, setFormBookingName] = useState({
    value: '',
    error: '',
  })
  const [formBookingPhone, setFormBookingPhone] = useState({
    value: '',
    error: '',
  })
  const [formBookingDate, setFormBookingDate] = useState({
    value: '',
    error: '',
  })
  const [formBookingTime, setFormBookingTime] = useState({
    value: '',
    error: '',
  })
  const [formBookingPerson, setFormBookingPerson] = useState({
    value: '',
    error: '',
  })

  function getApiUrl (uri = '') {
    const nodeEnv = 'prod'
    let path = 'https://api.80proof.club'
    
    if (nodeEnv === 'development') {
      path = 'https://api-dev.80proof.club'
    }
    if (nodeEnv === 'staging') {
      path = 'https://api-stg.80proof.club'
    }

    return `${path}${uri}`
  }

  function renderTodayEvent (events=[]) {
    const data = events.length ? events : []

    if (!events?.length) return false

    return (
      <div className="booking-events-wrap">
        <div className="title">Today Event</div>
        <div className="booking-events-box">
          {
            data.map((item, index) => (
              <div className="booking-event" key={index}>
                <img src={item.image} alt="event" className="image" />
                <div className="content-wrap">
                  <div className="text">{item.name}</div>
                  <div className="text">{item.artist}</div>
                  <div className="time">{item.time}</div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    )
  }

  function getDisabledHour () {
    let startBookTime = arrivalTimeConfig.start
    let endBookTime = arrivalTimeConfig.end

    const arr = []
    for (let i = 0; i < 24; i++) {
      if (startBookTime < endBookTime) {
        if (i < startBookTime || i > endBookTime) {
          arr.push(i)
        }
      } else {
        if (i > endBookTime && i < startBookTime) {
          arr.push(i)
        }
      }
    }

    return arr
  }

  function searchParams (params = '') {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(params)
  }

  useEffect(() => {
    let vConsole
    setToken(searchParams('token'))
    setTableId(searchParams('tableId') || searchParams('table'))
    setErrorText(searchParams('errorText'))
    const queryDate = searchParams('date')
    setFormBookingDate({ ...formBookingDate, value: queryDate ? dayjs(queryDate) : dayjs() })

    if (searchParams('debugging')) {
      vConsole = new VConsole()
    }

    if (errorText) {
      openModal(true, errorText)
    }

    if (tableId) {
      fetchAvailability()
    }
    return () => {
      if (searchParams('debugging')) {
        vConsole.destroy()
      }
    }
  }, [tableId])

  function handleBookingName (param) {
    const data = { ...formBookingName }
    data.value = param.target.value
    setFormBookingName(data)
  }
  function handleBookingPhone (param) {
    const data = { ...formBookingPhone }
    data.value = param.target.value
    setFormBookingPhone(data)
  }
  function handleBookingDate (date) {
    // const data = { ...formBookingDate }
    // data.value = date
    // setFormBookingDate(data)
    fetchAvailability(date)
  }
  function handleBookingPerson (val) {
    const data = { ...formBookingPerson }
    data.value = val
    setFormBookingPerson(data)
  }
  function handleBookingTime (val = '') {
    const data = { ...formBookingTime }
    data.value = val ? val.format('HH:mm') : ''
    setFormBookingTime(data)
  }

  function validatorBookingName (val) {
    const data = { ...formBookingName }
    if (!val) {
      data.error = 'this field cannot be empty'
      setFormBookingName(data)
      return false
    }

    data.error = ''
    setFormBookingName(data)
    return true
  }
  function validatorBookingPhone (val) {
    const data = { ...formBookingPhone }
    if (!val) {
      data.error = 'this field cannot be empty'
      setFormBookingPhone(data)
      return false
    }

    if (!(/^\d+$/.test(val))) {
      data.error = 'this field contain only number'
      setFormBookingPhone(data)
      return false
    }

    if (!(/^8\d*$/.test(val))) {
      data.error = 'Please use format phone'
      setFormBookingPhone(data)
      return false
    }

    data.error = ''
    setFormBookingPhone(data)
    return true
  }
  function validatorBookingDate (val) {
    const data = { ...formBookingDate }
    if (!val) {
      data.error = 'this field cannot be empty'
      setFormBookingDate(data)
      return false
    }

    if (tableStatus !== 'AVAILABLE') {
      data.error = 'The table is already taken, please choose another table'
      setFormBookingDate(data)
      return false
    }

    data.error = ''
    setFormBookingDate(data)
    return true
  }
  function validatorBookingTime (val) {
    const data = { ...formBookingTime }
    if (!val) {
      data.error = 'this field cannot be empty'
      setFormBookingTime(data)
      return false
    }

    data.error = ''
    setFormBookingTime(data)
    return true
  }

  function cacheDataBooking (data) {
    const bookingData = window.localStorage.getItem('bookingData')

    if (bookingData) {
      window.localStorage.removeItem('bookingData')
    }
    window.localStorage.setItem('bookingData', JSON.stringify(data))
  }
  
  function handleSubmitBooking () {
    setFormSubmitted(true)
    let requestApi = {}
    const isNameSafe = validatorBookingName(formBookingName.value)
    const isPhoneSafe = validatorBookingPhone(formBookingPhone.value)
    const isDateSafe = validatorBookingDate(formBookingDate.value)
    const isArrivalSafe = validatorBookingTime(formBookingTime.value)

    const formValidation = token
      ? (isDateSafe && isArrivalSafe) // after login
      : (isNameSafe && isPhoneSafe && isDateSafe && isArrivalSafe) // before login

    if ( formValidation ) {
      requestApi = {
        tableId: tableId ? parseInt(tableId) : tableId,
        date: formBookingDate.value ? dayjs(formBookingDate.value).format('YYYY-MM-DD') : formBookingDate.value,
        arrivalTime: formBookingTime.value,
        pax: formBookingPerson.value ? parseInt(formBookingPerson.value) : formBookingPerson.value,
      }
      
      if (!token) {
        requestApi.name = formBookingName.value
        requestApi.mobileNo = `0${formBookingPhone.value}`
      }

      fetchPayment(requestApi)
    }
  }

  function openModal (visible = false, text) {
    setModalVisible(visible)
    setModalText(text)
  }
  function handleModalClose (value = false) {
    setModalVisible(value)
  }

  function formatCurrency (currency = 0) {
    const price = currency.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
    return `Rp ${price}`;
  }

  function fetchPayment (requested) {

    const config = {
      method: 'post',
      url: getApiUrl('/payment'),
      data: requested
    }

    if (token) {
      config.headers = {
        'Authorization': `Bearer ${token}`,
      }
    }

    setLoading(true)
    axios(config).then((response = {}) => response.data ).then((res = {}) => {
      setLoading(false)

      if (res.isSuccess) {
        const data = (res.result && res.result.data) || {}
        cacheDataBooking({
          bookingName: requested.name,
          bookingPhone: requested.mobileNo,
          bookingDate: requested.date,
          bookingTable: tableName,
          bookingTime: requested.arrivalTime,
          bookingPerson: requested.pax,
        })

        if (!minCharge) {
          navigate('/success')
          return false
        }

        window.location.href = data.paymentUrl
      }
    }).catch((err) => {
      const errData = err?.response?.data || {}
      setLoading(false)
      openModal(true, errData?.message || '')
    })
  }

  function fetchEvent (date) {
    const url = getApiUrl(`/event/date/${date}`)
    axios({
      method: 'get',
      url,
    }).then((response = {}) => response.data ).then((res = {}) => {
      if (res.isSuccess) {
        const data = (res.result && res.result.data) || []
        const events = data?.length ? data.map((item) => {
          return {
            image: item.imageUrl,
            name: item.title,
            time: item.startTime,
          }
        }) : []

        setEventList(events)
      }
    }).catch((err) => {
      const errData = err?.response?.data || {}
      openModal(true, errData?.message || '')
    })

  }

  function fetchAvailability (date = '') {
    const currentDate = date ? dayjs(date).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')
    const url = getApiUrl(`/table/${tableId}?date=${currentDate}`)
    const configParam = {
      method: 'get',
      url,
    }

    if (token) {
      configParam.headers = {
        'Authorization': `Bearer ${token}`,
      }
    }

    axios(configParam).then((response = {}) => response.data ).then((res = {}) => {
      if (res.isSuccess) {
        const data = res?.result?.data || {}
        const name = data?.shortName || ''
        const dateError = data?.status !== 'AVAILABLE' ? 'The table is already taken, please choose another table' : ''

        const enumPax = []
        const minCapacity = data.capacity ? (data.capacity - 2) : 1
        for (let i = minCapacity; i <= data.capacity; i++) {
          enumPax.push(i)
        }

        setEnumMaxPerson(enumPax)
        setTableName(name)
        setTableStatus(data?.status)
        fetchEvent(currentDate)
        setMinCharge(data?.minCharge || 0)
        setTotalMinCharge(data?.originalMinCharge || 0)
        setUserTier(data?.tier || '')
        setUserTierDisc(data?.tierDisc || '')
        setFormBookingDate({ value: currentDate, error: dateError })

        if (data?.capacity) {
          setFormBookingPerson({ value: data?.capacity, error: '' })
        }
      }
      
    }).catch((err) => {
      const errData = err?.response?.data || {}
      openModal(true, errData?.message || '')
    })
  }

  return (
    <div className="main">
      <div className="container">
        <div className="booking-page">
          <Modal
            visible={modalVisible}
            text={modalText}
            onClose={handleModalClose}
          />
          <Spinner
            visible={loading}
          />

          <div className="header">
            <SvgIcon src={logo} color="white" />
          </div>
          <div className="table-number">
            <span>{ tableName }</span>
          </div>
          <div className="form">
            { !token && (
              <>
                <FormItem
                  label="Name"
                  isRequired={formSubmitted && !formBookingName.value}
                  errorMessage={formBookingName.error}
                >
                  <Input
                    placeholder="please enter your name"
                    onChange={handleBookingName}
                  />
                </FormItem>
                <FormItem
                  label="Phone Number"
                  isRequired={formSubmitted && !formBookingPhone.value}
                  errorMessage={formBookingPhone.error}
                >
                  <Input
                    addonBefore={'+62 | '}
                    placeholder="8123456"
                    onChange={handleBookingPhone}
                  />
                </FormItem>
              </>
            ) }
            <FormItem
              label="Select Date"
              isRequired={formSubmitted && !formBookingDate.value}
              errorMessage={formBookingDate.error}
              isSelect={true}
            >
              <DatePickerInput
                displayFormat="D MMMM YYYY"
                returnFormat="DD/MMMM/YYYY"
                value={dayjs(formBookingDate.value).format('DD/MMMM/YYYY')}
                onChange={handleBookingDate}
              />
            </FormItem>
            <FormItem
              label="estimated arrival time"
              isRequired={formSubmitted && !formBookingTime.value}
              errorMessage={formBookingTime.error}
              isSelect={true}
            >
              <TimePicker
                showSecond={false}
                use12Hours={false}
                minuteStep={arrivalTimeConfig.gap}
                disabledHours={() => getDisabledHour(3, 18)}
                hideDisabledOptions={true}
                popupClassName="rc-book-time"
                onChange={handleBookingTime}
              />
            </FormItem>
            
            <FormItem
              label="Pax"
              isSelect={true}
            >
              {
                formBookingPerson.value && (
                  <Select defaultValue={formBookingPerson.value} onChange={handleBookingPerson}>
                    {
                      enumMaxPerson.map((item, index) => (
                        <Option key={index} value={item}>{item}</Option>
                      ))
                    }
                  </Select>
                )
              }
            </FormItem>
            {
              !!minCharge && (
                <div className="min-charge">Minimum charge is { formatCurrency(totalMinCharge) } (50% payment required during booking, remaining 50% to be paid on-site).</div>
              )
            }
            {
              (userTier && userTierDisc !== '0%') && (
                <div className="min-charge">Discount { userTier } { userTierDisc }</div>
              )
            }
          </div>

          { renderTodayEvent(eventList) }

          <div className="booking-bottom-page">
            <div className="booking-info">
              In the event of any changes or if you need to cancel your reservation, please notify us as soon as possible by calling 0817-1771-7790. We understand that plans may change, and we appreciate your consideration in this regard.
            </div>

            <div className="button-wrap">
              <button
                className="btn btn-aqua-glow"
                onClick={handleSubmitBooking}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}