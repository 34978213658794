import React, { useEffect, useState } from "react";

export default function BookingExit () {
  const [device, setDevice] = useState(navigator.userAgent);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    let tempDevice = ''

    if (/iPhone/i.test(userAgent)) {
      tempDevice = 'iphone'
      setDevice('iphone');
    } else if (/Android/i.test(userAgent)) {
      tempDevice = 'android'
      setDevice('android');
    } else {
      tempDevice = 'unknown'
      setDevice('unknown');
    }

    if (tempDevice === 'unknown') {
      window.location.href = 'https://govirtual.id/virtual-tour/80proofultra/'
    }
  }, [])

  return (
    <>
      <div style={{ padding: '24px' }}>loading...</div>
    </>
  )
}