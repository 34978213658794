import React from 'react'
import { useState, useEffect } from 'react'
import dayjs from 'dayjs'

// Common Component
import SvgIcon from "./components/SvgIcon"
import LottieBox from './components/LottieBox'

// Asset
import logo from './assets/images/logo-80proof.svg'
import successLottie from './assets/lottie/checkmark_green.json'
import fireWorksLottie from './assets/lottie/fire_works.json'

export default function BookingSuccess() {
  const [transition, setTransition] = useState(false)
  const [stringCache, setStringCache] = useState('')
  const [cacheBooking, setCacheBooking] = useState({})
  const [currDevice, setCurrDevice] = useState('')

  useEffect(() => {
    setTransition(true)

    const bookingData = window.localStorage.getItem('bookingData')
    console.log(bookingData, typeof bookingData)

    setStringCache(bookingData || '')
    setCacheBooking(bookingData ? JSON.parse(bookingData) : {})
    getCurrentDevice()
  }, [])

  function getCurrentDevice () {
    const userAgent = navigator.userAgent;

    if (/iPhone/i.test(userAgent)) {
      setCurrDevice('iphone')
    } else if (/Android/i.test(userAgent)) {
      setCurrDevice('android')
    }
  }

  function infoItem (label, value) {
    return (
      <div className="info-wrap">
        <div className="text">{ label }</div>
        <div className="text">{ value }</div>
      </div>
    )
  }

  function renderInfoWrap () {
    const stringCacheLength = stringCache?.length
    if (stringCacheLength < 3) return false

    return (
      <div className="success-info-wrap">
        { cacheBooking?.bookingName && infoItem('Name', cacheBooking.bookingName) }
        { cacheBooking?.bookingPhone && infoItem('Phone Number', cacheBooking.bookingPhone) }
        { cacheBooking?.bookingDate && infoItem('Date', dayjs(cacheBooking.bookingDate).format('DD MMMM YYYY')) }
        { cacheBooking?.bookingTable && infoItem('table number', cacheBooking.bookingTable) }
        { cacheBooking?.bookingTime && infoItem('Time', cacheBooking.bookingTime) }
        { cacheBooking?.bookingPerson && infoItem('how many person', `${cacheBooking.bookingPerson} Pax`) }
      </div>
    )
  }

  return (
    <div className="main">
      <div className="container">
        <div className="booking-page success">
          <div className="header">
            <SvgIcon src={logo} color="white" />
          </div>
          

          <div className="success-header-wrap">
            <div className="fireworks-lottie">
              <LottieBox lottie={fireWorksLottie} opt={{ loop: true }} />
            </div>
            <div className="lottie">
              <LottieBox lottie={successLottie} />
            </div>
          </div>
          <div className={`transition${transition ? ' active' : ''}`}>
            <div className="success-header-wrap">
              <div className="text">your reservation <br /> is successfully made</div>
            </div>
            
            { renderInfoWrap() }

          </div>
          <div className="success-button-wrap">
            {
              currDevice && (
                <a href={'/exit'}>
                  <button
                    className="btn btn-aqua-glow"
                  >
                    Okay
                  </button>
                </a>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}