
import React from 'react'

export default function Spinner ({ visible = false, text = '' }) {

  if (visible) {   
      return (
        <div className="spinner">
          <div className="spinner-body">
            { text && ( <div className="text">{ text }</div> ) }
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
          </div>
        </div>
    )
  }

  return false
}